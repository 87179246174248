import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";

interface FolderContextType {
  openFolders: { [key: string]: boolean }; // Store open state by folder ID
  toggleFolder: (id: string) => void;
}

const FolderContext = createContext<FolderContextType | undefined>(undefined);

export const FolderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>(
    {}
  );
  const location = useLocation();
  useEffect(() => {
    setOpenFolders({}); // Resets to an empty object
  }, [location.pathname]);

  const toggleFolder = (id: string) => {
    setOpenFolders((prev) => {
      const newState = { ...prev };
      // Toggle the folder state by checking its current state and setting the opposite
      newState[id] = !newState[id];
      return newState;
    });
  };

  return (
    <FolderContext.Provider value={{ openFolders, toggleFolder }}>
      {children}
    </FolderContext.Provider>
  );
};

export const useFolder = () => {
  const context = useContext(FolderContext);
  if (context === undefined) {
    throw new Error("useFolder must be used within a FolderProvider");
  }
  return context;
};
