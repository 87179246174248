import googleIcon from "../../Assets/Icons/googleIcon.png";
import githubIcon from "../../Assets/Icons/githubIcon.png";
import bitbucketIcon from "../../Assets/Icons/bitbucketIcon.png";
import gitlabIcon from "../../Assets/Icons/gitlabIcon.png";
import checkMarkEllipseIcon from "../../Assets/Icons/checkMarkEllipseIcon.png";
import logo from "../../Assets/Logo/covlantLogoLightThemeWhiteColor.svg";
import { useAuth } from "../../auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "js-cookie";

function RegistrationPage() {
  const loginButtonStyle =
    "px-3 py-4 border-solid rounded-[4px] border-black/[.3] border flex flex-row w-[300px] md:w-[374px] gap-x-[16px] cursor-pointer";
  const loginButtonIconStyle = "pr-3 border-r border-black border-solid";
  const loginButtonTextStyle =
    "font-satoshi font-bold text-base text-center w-full items-center";

  // const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate(`/${Cookies.get("userName")}/repositories`);
  //   }
  // }, [isAuthenticated, navigate]);

  const handleGitHubLogin = async () => {
    try {
      window.location.href = `${process.env.REACT_APP_COVLANT_API_URL}/auth/login/github`;
    } catch (error) {
      console.error("Failed to initiate the GitHub OAuth flow", error);
    }
  };

  return (
    <div className="w-full h-screen max-w-[1440px] flex flex-col sm:flex-row sm:mx-auto justify-center">
      <div className="hidden sm:block sm:w-1/2 sm:h-full bg-loginPageLightTheme bg-cover bg-no-repeat">
        <div className="flex flex-col h-full items-center justify-between gap-28 font-santoshi ">
          <img
            src={logo}
            alt="logo"
            className="w-[200px] h-[200px] mt-[100px]"
          />
          <div className="flex flex-col gap-6 w-[351px]  pb-10 mb-10">
            <h3 className="text-[40px] font-medium text-white text-center tracking-[-0.03em] font-santoshi">
              The Democratisation of AI for Developers
            </h3>
            <div className="flex flex-col gap-3 items-center">
              <div className="flex flex-col items-start px-4 py-2 backdrop-blur-[77px] bg-[#ffffff1a] rounded-lg">
                <div className="flex flex-row items-center gap-2">
                  <img src={checkMarkEllipseIcon} alt="chechmark" />
                  <p className="font-medium text-base tracking-[-0.02em] text-white">
                    Overall quality of your team
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start px-4 py-2 backdrop-blur-[77px] bg-[#ffffff1a] rounded-lg">
                <div className="flex flex-row items-center gap-2">
                  <img src={checkMarkEllipseIcon} alt="chechmark" />
                  <p className="font-medium text-base tracking-[-0.02em] text-white">
                    Get everyone on the same page
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start px-4 py-2 backdrop-blur-[77px] bg-[#ffffff1a] rounded-lg">
                <div className="flex flex-row items-center gap-2">
                  <img src={checkMarkEllipseIcon} alt="chechmark" />
                  <p className="font-medium text-base tracking-[-0.02em] text-white">
                    Seamless integrations
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-1/2 w-full sm:w-1/2 sm:h-full">
        <div className="flex flex-col gap-20 justify-center items-center h-full">
          <div className="font-satoshi text-lg text-black font-normal">
            Welcome
          </div>
          <div className="flex flex-col gap-2">
            <button className={loginButtonStyle} onClick={handleGitHubLogin}>
              <div className={loginButtonIconStyle}>
                <img src={githubIcon} alt="github logo" />
              </div>
              <div className={loginButtonTextStyle}>Sign up with Github</div>
            </button>
            <button
              className={`${loginButtonStyle} border-none bg-[#c4c7cc7c] cursor-not-allowed focus:outline-none opacity-50`}
              disabled
            >
              <div className={loginButtonIconStyle}>
                <img src={gitlabIcon} alt="gitlab logo" />
              </div>
              <div className={loginButtonTextStyle}>Sign up with GitLab</div>
            </button>
            <button
              className={`${loginButtonStyle} border-none bg-[#c4c7cc7c] cursor-not-allowed focus:outline-none opacity-50`}
              disabled
            >
              <div className={loginButtonIconStyle}>
                <img src={bitbucketIcon} alt="bitbucket logo" />
              </div>
              <div className={loginButtonTextStyle}>Sign up with Bitbucket</div>
            </button>
            <button
              className={`${loginButtonStyle} border-none bg-[#c4c7cc7c] cursor-not-allowed focus:outline-none opacity-50`}
              disabled
            >
              <div className={loginButtonIconStyle}>
                <img src={googleIcon} alt="google logo" />
              </div>
              <div className={loginButtonTextStyle}>Sign up with Google</div>
            </button>
          </div>
          <div className="flex flex-col items-center sm:flex-row font-satoshi">
            <p className="text-[#00023F]/[.5] text-base px-1">
              Have an account?
            </p>
            <p
              className="text-[#00023F] text-bold cursor-pointer"
              onClick={() => (window.location.href = "/login")}
            >
              Login
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationPage;
