// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import reposiotryReducer from './repositorySlice';

const store = configureStore({
    reducer: {
        repository: reposiotryReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
