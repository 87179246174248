import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import mail from "../../Assets/Icons/mail.svg";
import call from "../../Assets/Icons/call.svg";
import { FaAddressCard } from "react-icons/fa";

function ContactPage() {
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    company: "",
    message: "",
  });

  const [touched, setTouched] = useState({
    first_name: false,
    email: false,
    company: false,
    message: false,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_COVLANT_API_URL}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the API response
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const shouldShowError = (field: keyof typeof touched) => {
    return !formData[field] && touched[field];
  };

  return (
    <div className="h-screen lg:min-h-[770px] flex flex-col items-center">
      <Header />
      <div className="flex flex-col justify-center gap-6 sm:w-[1170px] mt-4">
        <div className="font-satoshi text-5xl font-extrabold mx-auto">
          <p>CONTACT US</p>
        </div>
        <div className="flex flex-row mt-6">
          <div className="flex flex-col w-1/2">
            <div className="font-black text-4xl font-satoshi mb-4">
              How can we help?
            </div>
            <div className="font-satoshi font-base text-base mb-4">
              If you've got any questions, please fill out the form.
            </div>
            <div className="flex flex-row gap-3 items-center mb-3 font-satoshi">
              <div>
                <img src={mail} alt="mail" />
              </div>
              <div className="flex flex-col">
                <div>Email</div>
                <div>contact@covlant.info</div>
              </div>
            </div>
            <div className="flex flex-row gap-3 items-center mb-3 font-satoshi">
              <div>
                <img src={call} alt="call" />
              </div>
              <div className="flex flex-col">
                <div>Phone</div>
                <div>+44 7587 341 234</div>
              </div>
            </div>
            <div className="flex flex-row gap-3 items-center mb-3 font-satoshi">
              <div>
                <FaAddressCard size={22} />
              </div>
              <div className="flex flex-col">
                <div>
                  71-75 Shelton Street,
                  <br />
                  Covent Garden London,
                  <br />
                  WC2H 9JQ UK
                </div>
              </div>
            </div>
          </div>
          <div className="border w-1/2 shadow-lg shadow-gray-400">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center items-center my-4"
            >
              <div className="w-full px-8 my-2">
                <label
                  htmlFor="email"
                  className="block text-lg font-medium text-gray-700 pl-2"
                >
                  Email <span className="text-red-400">*</span>
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={formData.email}
                  className={`mt-1 block w-full px-3 py-2 bg-slate-100 ${
                    shouldShowError("email")
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-gray-50 rounded-md text-gray-900 focus:outline-none`}
                />
                {shouldShowError("first_name") && (
                  <p className="text-red-500 text-xs mt-1">
                    Please complete this required field.
                  </p>
                )}
              </div>
              <div className="w-full px-8 my-2">
                <label
                  htmlFor="first_name"
                  className="block text-lg font-medium text-gray-700 pl-2"
                >
                  First Name <span className="text-red-400">*</span>
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={formData.first_name}
                  className={`mt-1 block w-full px-3 py-2 bg-slate-100 ${
                    shouldShowError("first_name")
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-gray-50 rounded-md text-gray-900 focus:outline-none`}
                />
                {shouldShowError("first_name") && (
                  <p className="text-red-500 text-xs mt-1">
                    Please complete this required field.
                  </p>
                )}
              </div>
              <div className="w-full px-8 my-2">
                <label
                  htmlFor="last_name"
                  className="block text-lg font-medium text-gray-700 pl-2"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={formData.last_name}
                  className={`mt-1 block w-full px-3 py-2 bg-slate-100`}
                />
              </div>
              <div className="w-full px-8 my-2">
                <label
                  htmlFor="company"
                  className="block text-lg font-medium text-gray-700 pl-2"
                >
                  Company <span className="text-red-400">*</span>
                </label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={formData.company}
                  className={`mt-1 block w-full px-3 py-2 bg-slate-100 ${
                    shouldShowError("company")
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-gray-50 rounded-md text-gray-900 focus:outline-none`}
                />
                {shouldShowError("company") && (
                  <p className="text-red-500 text-xs mt-1">
                    Please complete this required field.
                  </p>
                )}
              </div>
              <div className="w-full px-8 my-2">
                <label
                  htmlFor="message"
                  className="block text-lg font-medium text-gray-700 pl-2"
                >
                  Message <span className="text-red-400">*</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  required
                  onChange={handleMessageChange}
                  value={formData.message}
                  className={`mt-1 block w-full px-3 py-2 bg-slate-100 ${
                    shouldShowError("message")
                      ? "border-red-500"
                      : "border-gray-300"
                  } bg-gray-50 rounded-md text-gray-900 focus:outline-none`}
                />
                {shouldShowError("message") && (
                  <p className="text-red-500 text-xs mt-1">
                    Please complete this required field.
                  </p>
                )}
              </div>
              <br />
              <div className="w-full flex items-center justify-between mt-2">
                <button
                  type="submit"
                  className="w-full mx-8 bg-[#2742AB] text-white hover:bg-blue-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
