import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import copyIcon from "../../Assets/Icons/copyIcon.png";
import checkmarkIcon from "../../Assets/Icons/checkmarkIcon.png";
import downloadIcon from "../../Assets/Icons/downloadIcon.png";
import { fetchFiles } from "../../utils/file";
import { setFiles } from "../../redux/repositorySlice";
import loadingSpinner from "../../Assets/Icons/loadingSpinner.gif";

const TestCase: React.FC = () => {
  const currentFile = useSelector((state: RootState) => state.repository.currentFile);
  const currentRepository = useSelector((state: RootState) => state.repository.currentRepository);

  const [sourceCode, setSourceCode] = useState<string>("");
  const [testCode, setTestCode] = useState<string>("");

  const [sourceCodeLOCandSize, setSourceCodeLOCandSize] = useState({ lines: 0, size: 0 });
  const [testCodeLOCandSize, setTestCodeLOCandSize] = useState({ lines: 0, size: 0 });

  const dispatch = useDispatch();

  const computeLOCandSize = (code: string) => {
    const lines = code.split("\n").length;
    const size = new Blob([code]).size;
    return { lines, size };
  };

  const userName = Cookies.get("userName");

  const base_url = `${process.env.REACT_APP_COVLANT_API_URL}/${userName}/${currentRepository?.name}/${currentFile?.id}`;

  const downloadStringAsFile = (content: string, filename: string) => {
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    const content = testCode;
    const filename = `${currentFile?.name}_test${currentFile?.type}`;
    downloadStringAsFile(content, filename);
  };

  const get_source_code = useCallback(async () => {
    try {
      axios.defaults.withCredentials = true;
      axios
        .post(
          `${base_url}/source_code/`,
          {
            file_id: currentFile?.id,
          },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data) {
            setSourceCode(res.data.source_code);
            setSourceCodeLOCandSize(computeLOCandSize(res.data.source_code));
          }
        });
    } catch (error) {
      console.error(error);
    }
  }, [base_url, currentFile?.id]);

  const updateCurrentFiles = async () => {
    if (currentRepository)
      fetchFiles(currentRepository).then((res) => {
        if (res?.data) {
          dispatch(setFiles(res.data));
        }
      });
  };

  const get_test_code = useCallback(async () => {
    try {
      axios.defaults.withCredentials = true;
      axios
        .post(
          `${base_url}/test_cases/`,
          {
            file_id: currentFile?.id,
          },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data) {
            setTestCode(res.data.test_code);
            setTestCodeLOCandSize(computeLOCandSize(res.data.test_code));
            updateCurrentFiles();
          }
        });
    } catch (error) {
      setTestCode("Something went wrong please contact ADMIN.");
      console.error(error);
    }
  }, [base_url, currentFile?.id]);

  const saveTestCode = async () => {
    try {
      axios.defaults.withCredentials = true;
      axios
        .post(
          `${base_url}/test_cases/save`,
          {
            file_id: currentFile?.id,
            test_code: testCode,
          },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data) {
            console.log(res.data);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (currentFile) {
      get_source_code();
      get_test_code();
    }
  }, [currentFile, get_source_code, get_test_code]);

  return (
    <div className="flex flex-col gap-8 items-start w-screen font-satoshi mx-auto">
      <div className="flex flex-row justify-between gap-2 w-full mx-auto max-w-[1170px]">
        <div className="flex flex-col items-start gap-2">
          <div className="font-bold text-2xl tracking-[-0.03em] text-[#0C101E]">{currentFile?.name}</div>
          <div className="flex flex-row gap-2 items-center">
            <ol className="list-reset rounded flex flex-row gap-2 bg-grey-light text-grey">
              <li>
                <Link to={`/${userName}/${currentRepository?.name}/overview`} className="no-underline text-[#0581E5]">
                  {currentRepository?.name}
                </Link>
              </li>
              <li>/</li>
              <li>
                <Link to={`/${userName}/${currentRepository?.name}/files`} className="no-underline text-[#0581E5]">
                  Files
                </Link>
              </li>
              <li>/</li>
              <li>{currentFile?.name}</li>
            </ol>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full max-w-[1440px]">
        {/* Original Code file */}
        <div className="flex flex-col p-6 gap-6 w-1/2 max-w-[720px] bg-[#F9FBFC] border-[1.6px] border-[#EAEBEF]">
          <div className="flex flex-row gap-8 font-satoshi">
            <p className="font-bold text-[15px] text-[#353B42]">Source code</p>
            {sourceCodeLOCandSize.lines !== 0 && (
              <div className="flex flex-row items-center gap-2 text-[#5C6068] font-medium text-sm">
                <p>{sourceCodeLOCandSize.lines} Lines</p>
                <p className="text-center align-middle">•</p>
                <p>{sourceCodeLOCandSize.size} Bytes</p>
              </div>
            )}
          </div>
          <SyntaxHighlighter
            language="java"
            showLineNumbers
            customStyle={{
              width: "100%",
              backgroundColor: "#F9FBFC",
              borderRadius: "4px",
              border: "None",
              paddingLeft: "0px",
              fontFamily: "Satoshi Variable",
              fontSize: "16px",
              lineHeight: "27px",
              fontWeight: "500",
            }}
            lineNumberContainerStyle={{
              padding: "8px 12px 8px 16px",
              backgroundColor: "#F9FBFC",
              borderRight: "1.6px solid #EAEBEF",
              borderRadius: "4px 0px 0px 4px",
            }}
            lineNumberStyle={{
              color: "#747B83",
              fontFamily: "Satoshi, sans-serif",
              fontSize: "14px",
              lineHeight: "19px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {sourceCode}
          </SyntaxHighlighter>
        </div>

        {/* Test code */}
        <div className="flex flex-col p-6 gap-6 w-1/2 max-w-[720px] bg-[#F9FBFC] border-[1.6px] border-[#EAEBEF]">
          <div className="flex flex-row gap-8 font-satoshi items-center">
            <p className="font-bold text-[15px] text-[#353B42]">Test code</p>
            {testCodeLOCandSize.size !== 0 && (
              <div className="flex flex-row items-center gap-2 text-[#5C6068] font-medium text-sm">
                <p>{testCodeLOCandSize.lines} Lines</p>
                <p className="text-center align-middle">•</p>
                <p>{testCodeLOCandSize.size} Bytes</p>
              </div>
            )}
            {testCode && (
              <div className="flex flex-row items-center gap-4 ml-auto">
                <div className="flex flex-row py-3 px-4 rounded-lg border-[1.6px] border-solid border-[#EAEBEF] cursor-pointer" onClick={handleDownload}>
                  <img src={downloadIcon} alt="Download" />
                </div>
                <div
                  className="flex flex-row py-3 px-4 rounded-lg border-[1.6px] border-solid border-[#EAEBEF] cursor-pointer"
                  onClick={() => navigator.clipboard.writeText(testCode)}
                >
                  <img src={copyIcon} alt="Copy" />
                </div>
                <div className="flex flex-row items-center py-3 px-4 bg-[#0581E5] gap-2 rounded-lg cursor-pointer" onClick={saveTestCode}>
                  <img src={checkmarkIcon} alt="Save" />
                  <p className="text-white">Save Code</p>
                </div>
              </div>
            )}
          </div>
          {testCode ? (
            <SyntaxHighlighter
              language="java"
              showLineNumbers
              customStyle={{
                width: "100%",
                backgroundColor: "#F9FBFC",
                borderRadius: "4px",
                border: "None",
                paddingLeft: "0px",
                fontFamily: "Satoshi Variable",
                fontSize: "16px",
                lineHeight: "27px",
                fontWeight: "500",
              }}
              lineNumberContainerStyle={{
                padding: "8px 12px 8px 16px",
                backgroundColor: "#F9FBFC",
                borderRight: "1.6px solid #EAEBEF",
                borderRadius: "4px 0px 0px 4px",
              }}
              lineNumberStyle={{
                color: "#747B83",
                fontFamily: "Satoshi, sans-serif",
                fontSize: "14px",
                lineHeight: "19px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {testCode}
            </SyntaxHighlighter>
          ) : (
            <div className="flex flex-col items-center justify-center gap-4">
              <img src={loadingSpinner} alt="loading" className="h-14 w-14" />
              <div className="text-black font-bold font-satoshi text-base">Please wait Test cases are getting generated...</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestCase;
