import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./auth";

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const { isAuthenticated, isLoading } = useAuth();

  let location = useLocation();

  if (isLoading) {
    console.log("loading");
  } else if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }

  return children;
}

export default ProtectedRoute;
