import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import tick from "../../Assets/Icons/tick-icon.png";

const BetaSignup = () => {
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    role: "",
    company: "",
    languages: {
      java: false,
      python: false,
      js: false,
      ts: false,
      "c#": false,
      golang: false,
      c: false,
      "c++": false,
      ruby: false,
      scala: false,
      kotlin: false,
      rust: false,
    },
    newsletter: false,
  });

  const [touched, setTouched] = useState({
    first_name: false,
    email: false,
    role: false,
  });

  const [emailError, setEmailError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [languageError, setLanguageError] = useState("");
  const [newsletterError, setNewsletterError] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      languages: {
        ...formData.languages,
        [e.target.name]: e.target.checked,
      },
    });
  };

  const validateEmail = (email: string) => {
    // Regular expression for email validation
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleNewsletterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      newsletter: e.target.checked,
    });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const shouldShowError = (field: keyof typeof touched) => {
    return !formData[field] && touched[field];
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate form data
    const fields: Array<keyof typeof touched> = ["first_name", "email", "role"];
    let isValid = true;
    for (const field of fields) {
      if (!formData[field]) {
        setTouched({
          ...touched,
          [field]: true,
        });
        isValid = false;
      }
    }

    // Validate email
    if (!validateEmail(formData.email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

    const isLanguageSelected = Object.values(formData.languages).some(Boolean);
    if (!isLanguageSelected) {
      setLanguageError("Please select at least one language.");
      isValid = false;
    } else {
      setLanguageError("");
    }

    // Validate newsletter - should be true
    if (!formData.newsletter) {
      setNewsletterError("Please agree to receive the newsletter.");
      isValid = false;
    } else {
      setNewsletterError("");
    }

    // If form data is valid, send a POST request
    if (isValid) {
      setIsSubmitted(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_COVLANT_API_URL}/beta-signup/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // Handle response here
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="h-screen lg:min-h-[770px] flex flex-col">
      <Header />
      {isSubmitted ? (
        <div className="flex flex-col gap-4 justify-center items-center h-3/4">
          <img className="max-w-[50px]" src={tick} alt="success" />
          <div className="text-lg font-santoshi">
            Thanks you for signing up!
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center gap-3">
            <div className="font-satoshi text-xl font-thin">COVLANT BETA</div>
            <div className="text-black font-satoshi text-center sm:text-left font-normal text-xl sm:text-5xl">
              Register for Covlant beta
            </div>
          </div>
          <div className="mt-4 flex justify-center items-center">
            <div className="flex justify-center items-center w-full sm:max-w-[1170px] bg-gray-200">
              <form
                onSubmit={handleSubmit}
                className="w-full m-2 p-4 flex flex-col gap-6"
              >
                <div className="flex flex-row gap-6 w-full">
                  <div className="w-1/2">
                    <label
                      htmlFor="first_name"
                      className="block text-lg font-medium text-gray-700"
                    >
                      First Name *
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={formData.first_name}
                      className={`mt-1 block w-full px-3 py-2 ${
                        shouldShowError("first_name")
                          ? "border-red-500"
                          : "border-gray-300"
                      } bg-gray-50 rounded-md text-gray-900 focus:outline-none`}
                    />
                    {shouldShowError("first_name") && (
                      <p className="text-red-500 text-xs mt-1">
                        Please complete this required field.
                      </p>
                    )}
                  </div>
                  <div className="w-1/2">
                    <label
                      htmlFor="last_name"
                      className="block text-lg font-medium text-gray-700"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={formData.last_name}
                      className={`mt-1 block w-full px-3 py-2 bg-gray-50 rounded-md text-gray-900 focus:outline-none`}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <div className="w-full">
                    <label
                      htmlFor="Email"
                      className="block text-lg font-medium text-gray-700"
                    >
                      Email *
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={formData.email}
                      className={`mt-1 block w-full px-3 py-2 ${
                        shouldShowError("email")
                          ? "border-red-500"
                          : "border-gray-300"
                      } bg-gray-50 rounded-md text-gray-900 focus:outline-none`}
                    />
                    {shouldShowError("email") && (
                      <p className="text-red-500 text-xs mt-1">
                        Please complete this required field.
                      </p>
                    )}
                    {emailError && (
                      <p className="text-red-500 text-xs mt-1">{emailError}</p>
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-row gap-6">
                  <div className="w-1/2">
                    <label
                      htmlFor="company"
                      className="block text-lg font-medium text-gray-700"
                    >
                      Company
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={formData.company}
                      className={`mt-1 block w-full px-3 py-2 bg-gray-50 rounded-md text-gray-900 focus:outline-none`}
                    />
                  </div>
                  <div className="w-1/2">
                    <label
                      htmlFor="role"
                      className="block text-lg font-medium text-gray-700"
                    >
                      Role *
                    </label>
                    <input
                      type="text"
                      id="role"
                      name="role"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={formData.role}
                      className={`mt-1 block w-full px-3 py-2 ${
                        shouldShowError("role")
                          ? "border-red-500"
                          : "border-gray-300"
                      } bg-gray-50 rounded-md text-gray-900 focus:outline-none`}
                    />
                    {shouldShowError("role") && (
                      <p className="text-red-500 text-xs mt-1">
                        Please complete this required field.
                      </p>
                    )}
                  </div>
                </div>

                <div className="w-5/6">
                  <label className="block text-lg font-medium text-gray-700">
                    Please specify which programming languages you regularly
                    use.
                  </label>
                  <div className="mt-3 flex flex-col gap-2">
                    <div className="flex flex-row">
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2 w-1/3">
                        <input
                          type="checkbox"
                          name="java"
                          onChange={handleCheckboxChange}
                        />
                        Java
                      </label>
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2  w-1/3">
                        <input
                          type="checkbox"
                          name="python"
                          onChange={handleCheckboxChange}
                        />
                        Python
                      </label>
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2  w-1/3">
                        <input
                          type="checkbox"
                          name="js"
                          onChange={handleCheckboxChange}
                        />
                        Javascript
                      </label>
                    </div>
                    <div className="flex flex-row">
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2  w-1/3">
                        <input
                          type="checkbox"
                          name="ts"
                          onChange={handleCheckboxChange}
                        />
                        Typescript
                      </label>
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2  w-1/3">
                        <input
                          type="checkbox"
                          name="c#"
                          onChange={handleCheckboxChange}
                        />
                        C#
                      </label>
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2  w-1/3">
                        <input
                          type="checkbox"
                          name="golang"
                          onChange={handleCheckboxChange}
                        />
                        Golang
                      </label>
                    </div>
                    <div className="flex flex-row">
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2  w-1/3">
                        <input
                          type="checkbox"
                          name="c"
                          onChange={handleCheckboxChange}
                        />
                        C
                      </label>
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2  w-1/3">
                        <input
                          type="checkbox"
                          name="c++"
                          onChange={handleCheckboxChange}
                        />
                        C++
                      </label>
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2  w-1/3">
                        <input
                          type="checkbox"
                          name="ruby"
                          onChange={handleCheckboxChange}
                        />
                        Ruby
                      </label>
                    </div>
                    <div className="flex flex-row">
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2  w-1/3">
                        <input
                          type="checkbox"
                          name="scala"
                          onChange={handleCheckboxChange}
                        />
                        Scala
                      </label>
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2  w-1/3">
                        <input
                          type="checkbox"
                          name="kotlin"
                          onChange={handleCheckboxChange}
                        />
                        Kotlin
                      </label>
                      <label className="flex flex-row gap-2 block text-[#666666] text-base font-bold mb-2  w-1/3">
                        <input
                          type="checkbox"
                          name="rust"
                          onChange={handleCheckboxChange}
                        />
                        Rust
                      </label>
                    </div>
                  </div>
                  {languageError && (
                    <p className="text-red-500 text-xs mt-1">{languageError}</p>
                  )}
                </div>

                <div className=" w-full mb-4">
                  <label className="block text-[#666666] text-base font-bold mb-2 flex flex-row gap-3">
                    <input
                      type="checkbox"
                      name="newsletter"
                      onChange={handleNewsletterChange}
                    />
                    I agree to receive other communications from Covlant. You
                    can unsubscribe from these communications at any time. For
                    more information on how to unsubscribe, our privacy
                    practices, and how we are committed to protecting and
                    respecting your privacy, please review our Privacy Policy.*
                  </label>
                  {newsletterError && (
                    <p className="text-red-500 text-xs mt-1">
                      {newsletterError}
                    </p>
                  )}
                </div>
                <div className="w-full flex items-center justify-between">
                  <button
                    type="submit"
                    className="w-full bg-[#2742AB] text-white hover:bg-blue-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BetaSignup;
