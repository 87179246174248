import axios from "axios";
import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";

// Create a context for the authentication status, loading status and the functions to update them
export const AuthContext = createContext<
  | {
      isAuthenticated: boolean | undefined;
      setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean | undefined>>;
      isLoading: boolean;
      setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(false);
  const [isLoading, setIsLoading] = useState(true);

  // Function to check the session
  const checkSession = async () => {
    axios.defaults.withCredentials = true;
    axios.get(`${process.env.REACT_APP_COVLANT_API_URL}/validate/`, { withCredentials: true }).then((res) => {
      setIsAuthenticated(res.data?.valid);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    checkSession(); // Call immediately on mount
    const interval = setInterval(checkSession, 15 * 60 * 1000); // Check every 15 minutes
    return () => clearInterval(interval); // Clear the interval when the component is unmounted
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, isLoading, setIsLoading }}>{children}</AuthContext.Provider>;
};

// Hook to use the authentication status in your components
export function useAuth() {
  const authState = useContext(AuthContext);
  if (authState === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return authState;
}
