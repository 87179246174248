import React, { useState, useEffect, Fragment, useCallback } from "react";
import addRepositoryIcon from "../../Assets/Icons/addRepositoryIcon.png";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import Repository from "../../Interfaces/Repository";
import Cookies from "js-cookie";
import cancelIcon from "../../Assets/Icons/cancelIcon.png";
import loadingSpinner from "../../Assets/Icons/loadingSpinner.gif";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  setCurrentRepository,
  setFiles,
  setIssues,
  setRepositories,
  setRepositoriesSummary,
} from "../../redux/repositorySlice";
import { v1 as uuidv1 } from "uuid";
import ProcessProgress from "../ProcessProgress/ProcessProgress";

const ManageRepositories = () => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<string>("add");
  const [selectedRepo, setSelectedRepo] = useState<Repository>();
  const [
    isAddOrRemoveRepositoryDialogOpen,
    setIsAddOrRemoveRepositoryDialogOpen,
  ] = useState(false);

  const [requestId, setRequestId] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const repositories = useSelector(
    (state: RootState) => state.repository.repositories
  );

  const fetchRepositories = useCallback(async () => {
    console.log("Fetching repositories...");
    try {
      axios.defaults.withCredentials = true;
      const res = await axios.post(
        `${process.env.REACT_APP_COVLANT_API_URL}/${Cookies.get(
          "userName"
        )}/repos/`,
        { is_all: true },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
          withCredentials: true,
        }
      );
      dispatch(setRepositories(res.data));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!(repositories && repositories.length)) {
      fetchRepositories();
    }
  }, [fetchRepositories, repositories]);

  const dialogAddOrRemoveButtonStyle =
    "px-4 py-3 text-white rounded-lg font-bold text-base font-satoshi";

  const handleOpen = () => {
    setOpen(true);
  };

  const openRepository = (repository: Repository) => {
    dispatch(setCurrentRepository(repository));
    dispatch(setFiles([]));
    dispatch(setIssues([]));
    localStorage.setItem("repository", JSON.stringify(repository));
    navigate(`/${Cookies.get("userName")}/repositories/${repository.name}`);
  };

  const handleAddOrRemoveRepository = async (
    repo: Repository,
    action: string
  ) => {
    setOpen(false);
    setAction(action);
    setSelectedRepo(repo);
    setIsAddOrRemoveRepositoryDialogOpen(true);
    try {
      axios.defaults.withCredentials = true;
      const newRequestId = uuidv1();
      await setRequestId(newRequestId);
      const response = await axios.post(
        `${process.env.REACT_APP_COVLANT_API_URL}/${Cookies.get(
          "userName"
        )}/repository/${action}`,
        { repository_id: repo.id, request_id: newRequestId },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
          withCredentials: true,
        }
      );
      if (response.status === 201) {
        setIsAddOrRemoveRepositoryDialogOpen(false);
        dispatch(setRepositoriesSummary(null));
        await fetchRepositories(); // Wait for fetchRepositories to complete
        if (action === "remove") {
          if (
            repositories &&
            repositories.filter((repository) => repository.is_added_to_covlant)
              .length === 0
          ) {
            navigate(`/${Cookies.get("userName")}/dashboard`);
          }
        } else if (action === "add") {
          openRepository(repo);
        }
      } else {
        setIsAddOrRemoveRepositoryDialogOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="flex flex-row items-center justify-center gap-4 bg-[#B1D5F6] rounded-[76px] p-3 pr-4 cursor-pointer"
      onClick={handleOpen}
    >
      <img src={addRepositoryIcon} alt="plus" className="h-4 w-4" />
      {repositories &&
      repositories.filter((repository) => repository.is_added_to_covlant)
        .length === 0 ? (
        <div className="text-black font-bold font-satoshi text-base">
          Add repositories
        </div>
      ) : (
        <div className="text-black font-bold font-satoshi text-base">
          Manage repositories
        </div>
      )}

      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 "
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 flex flex-col gap-8 text-left shadow-xl transition-all">
                  <div className="text-2xl font-satoshi font-bold flex flex-row justify-between items-center">
                    <div>Manage repositories</div>
                    <div
                      className="pr-4 cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <button className="w-4 h-4">
                        <img src={cancelIcon} alt="Close" />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col border-[1.6px] border-solid border-[#EAEBEF] rounded-lg">
                    {repositories &&
                      repositories.map((repo) => (
                        <div
                          key={repo.id}
                          className="flex flex-row gap-8 p-4 border-b-[1.6px] border-solid border-[#EAEBEF] justify-between items-center"
                        >
                          <div>{repo.name}</div>
                          {repo.is_added_to_covlant ? (
                            <button
                              className={`bg-red-400 ${dialogAddOrRemoveButtonStyle}`}
                              onClick={() =>
                                handleAddOrRemoveRepository(repo, "remove")
                              }
                            >
                              Remove
                            </button>
                          ) : (
                            <button
                              className={`bg-[#0581E5] ${dialogAddOrRemoveButtonStyle}`}
                              onClick={() =>
                                handleAddOrRemoveRepository(repo, "add")
                              }
                            >
                              Add
                            </button>
                          )}
                        </div>
                      ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition show={isAddOrRemoveRepositoryDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10  h-[304px]"
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[573px] transform overflow-hidden rounded-2xl bg-white p-6 flex flex-col gap-8 text-left shadow-xl transition-all">
                  <Dialog.Title className="text-2xl font-satoshi font-bold flex flex-row justify-center items-center gap-2">
                    {action === "add" ? (
                      <div> Adding </div>
                    ) : (
                      <div> Removing </div>
                    )}
                    <div>{selectedRepo?.name}</div>
                  </Dialog.Title>

                  <Dialog.Description className="flex flex-col  h-[200px] items-center justify-center">
                    {action === "add" ? (
                      <ProcessProgress
                        url={`${process.env.REACT_APP_COVLANT_API_URL}/process-step/${requestId}/ADDING_REPOSITORY/`}
                      />
                    ) : (
                      <div className="flex flex-col items-center justify-center gap-4">
                        <img
                          src={loadingSpinner}
                          alt="loading"
                          className="h-14 w-14"
                        />
                        <div className="text-black font-bold font-satoshi text-base">
                          Please wait...
                        </div>
                      </div>
                    )}
                  </Dialog.Description>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default ManageRepositories;
