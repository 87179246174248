import axios from "axios";
import Repository from "../Interfaces/Repository";
import Cookies from "js-cookie";

export async function fetchFiles(repository: Repository) {
    try {
        axios.defaults.withCredentials = true;
        const res = await axios.post(
            `${process.env.REACT_APP_COVLANT_API_URL}/${Cookies.get("userName")}/${repository?.name}/files/`,
            { repository_id: repository?.id },
            {
                headers: {
                    "X-CSRFToken": Cookies.get("csrftoken"),
                },
                withCredentials: true,
            }
        );
        return res
    } catch (error) {
        console.error(error);
    }
};
