import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Editor, OnMount } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import "./CodeEditor.css";
import { HighlightLine } from "./HighlightLine";

interface CodeEditorProps {
  code: string;
  highlightLines: HighlightLine[];
  isReadOnly: boolean;
  onSave?: (newCode: string) => void;
  onLineClick?: (lineNumber: number) => void;
  restFocusLine?: () => void;
}

export interface CodeEditorMethods {
  focusLine: (lineNumber: number) => void;
}

const CodeEditor = forwardRef<CodeEditorMethods, CodeEditorProps>(
  (CodeEditorProps, ref) => {
    const {
      code,
      highlightLines,
      isReadOnly,
      onSave,
      onLineClick,
      restFocusLine,
    } = CodeEditorProps;
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
    const decorationsRef = useRef<string[]>([]);

    useImperativeHandle(ref, () => ({
      focusLine(lineNumber: number) {
        if (editorRef.current) {
          editorRef.current.revealLineInCenter(lineNumber);
          editorRef.current.setPosition({ lineNumber: lineNumber, column: 1 });
          editorRef.current.focus();
        }
      },
    }));

    const handleEditorDidMount = (
      editor: monaco.editor.IStandaloneCodeEditor
    ) => {
      editorRef.current = editor;
      editor.updateOptions({ readOnly: isReadOnly });

      if (highlightLines.length > 0) {
        applyDecorations();
      }

      editor.onMouseDown((e: any) => {
        if (
          e.target.type === monaco.editor.MouseTargetType.GUTTER_LINE_NUMBERS
        ) {
          const lineNumber = e.target.position.lineNumber;
          if (onLineClick) {
            onLineClick(lineNumber);
          }

          if (restFocusLine) {
            restFocusLine();
          }
        } else if (
          e.target.type === monaco.editor.MouseTargetType.CONTENT_TEXT
        ) {
          if (restFocusLine) {
            restFocusLine();
          }
        }
      });

      editor.onDidScrollChange((event) => {
        if (restFocusLine) {
          restFocusLine();
        }
      });
    };

    const saveCode = () => {
      if (editorRef.current) {
        const currentCode = editorRef.current.getValue();
        if (onSave) {
          onSave(currentCode);
        }
      }
    };

    useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
        if ((event.ctrlKey || event.metaKey) && event.key === "s") {
          event.preventDefault();
          saveCode();
        }
      };

      window.addEventListener("keydown", handleKeyPress);
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }, []);

    const applyDecorations = () => {
      if (editorRef.current) {
        const editorContent = editorRef.current.getValue();
        if (editorContent && editorContent.length > 0) {
          const decorations = highlightLines.map((line) => {
            return {
              range: new monaco.Range(line.lineNumber, 1, line.lineNumber, 1),
              options: {
                isWholeLine: true,
                className: `line-highlight-${line.color}`,
              },
            };
          });

          decorationsRef.current = editorRef.current.deltaDecorations(
            decorationsRef.current,
            decorations
          );
        } else {
          setTimeout(applyDecorations, 500);
        }
      }
    };

    // useEffect(() => {
    //   if (editorRef.current) {
    //     editorRef.current.setValue(code);
    //     editorRef.current.updateOptions({ readOnly: isReadOnly });
    //     applyDecorations();
    //   }
    // }, [code, highlightLines, isReadOnly]);

    useEffect(() => {
      if (editorRef.current) {
        editorRef.current.setValue(code);
      }
    }, [code]);

    useEffect(() => {
      if (editorRef.current) {
        applyDecorations();
      }
    }, [highlightLines]);

    return (
      <div className="editor-container">
        <Editor
          height="600px"
          defaultLanguage="java"
          defaultValue={code}
          theme="vs-light"
          onMount={handleEditorDidMount}
          options={{
            readOnly: isReadOnly,
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
          }}
        />
      </div>
    );
  }
);

export default CodeEditor;
