import { useCallback, useEffect } from "react";
import Repository from "../../Interfaces/Repository";
import axios from "axios";
import addRepositoryIcon from "../../Assets/Icons/addRepositoryIcon.png";
import codeIcon from "../../Assets/Icons/codeIcon.png";
import diamondIcon from "../../Assets/Icons/diamondIcon.png";
import ManageRepositories from "../../Components/ManageRepositories/ManageRepositories";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentRepository,
  setFiles,
  setIssues,
  setRepositoriesSummary,
} from "../../redux/repositorySlice";
import { RootState } from "../../redux/store";
import Moment from "moment";

function RepositoriesPage() {
  const repoList = useSelector((state: RootState) =>
    state.repository.repositories?.filter((repo) => repo.is_added_to_covlant)
  );
  const repositoriesSummary = useSelector(
    (state: RootState) => state.repository.repositoriesSummary
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const repositoryOverviewCardStyle =
    "flex flex-col gap-10 border-[1.6px] border-[#EAEBEF] rounded-2xl p-6";
  const repositoryOverviewCardIconStyle =
    "border-solid border-[#EAEBEF] rounded-2xl border p-4 flex flex-row w-14";
  const repositoryOverviewCardContentStyle =
    "flex flex-col font-satoshi gap-[8px]";
  const repositoryOverviewCardContentTitleStyle =
    "text-[#132052]/[.6] font-normal text-base";
  const repositoryOverviewCardContentValueStyle =
    "text-[#0581E5] font-bold text-xl";

  const openRepository = (repository: Repository) => {
    dispatch(setCurrentRepository(repository));
    dispatch(setFiles([]));
    dispatch(setIssues([]));
    localStorage.setItem("repository", JSON.stringify(repository));
    navigate(`/${Cookies.get("userName")}/repositories/${repository.name}`);
  };

  useEffect(() => {
    if (repoList && repoList.length === 0) {
      navigate(`/${Cookies.get("userName")}/dashboard`);
    }
  }, [repoList, navigate]);

  const repositoryPageSummary = useCallback(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_COVLANT_API_URL}/${Cookies.get(
          "userName"
        )}/repositories/summary`,
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
          withCredentials: true,
        }
      );
      if (res.data.data) {
        dispatch(setRepositoriesSummary(res.data.data));
      }
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (repositoriesSummary === null) {
      repositoryPageSummary();
    }
  }, [repositoriesSummary, repositoryPageSummary]);

  return (
    <div className="flex flex-col flex-grow gap-16 max-w-[1170px] mx-auto font-satoshi">
      {/* <div className="flex flex-col gap-8">
        <div className="flex flex-row items-center justify-between">
          <h1 className="text-3xl font-bold text-[#132052] tracking-[-.03em]">
            Repositories
          </h1>
        </div>
        <div className="grid grid-col-1 sm:grid-cols-3 gap-6">
          <div className={repositoryOverviewCardStyle}>
            <div className={repositoryOverviewCardIconStyle}>
              <img src={addRepositoryIcon} alt="plus" />
            </div>
            <div className={repositoryOverviewCardContentStyle}>
              <p className={repositoryOverviewCardContentTitleStyle}>
                Repositories
              </p>
              <p className={repositoryOverviewCardContentValueStyle}>
                {repositoriesSummary?.total_repositories}
              </p>
            </div>
          </div>
          <div className={repositoryOverviewCardStyle}>
            <div className={repositoryOverviewCardIconStyle}>
              <img src={codeIcon} alt="code" />
            </div>
            <div className={repositoryOverviewCardContentStyle}>
              <p className={repositoryOverviewCardContentTitleStyle}>
                LOC analysed
              </p>
              <p className={repositoryOverviewCardContentValueStyle}>
                {repositoriesSummary?.total_loc_analysed ?? "-"}
              </p>
            </div>
          </div>
          <div className={repositoryOverviewCardStyle}>
            <div className={repositoryOverviewCardIconStyle}>
              <img src={diamondIcon} alt="maintainabilty" />
            </div>
            <div className={repositoryOverviewCardContentStyle}>
              <p className={repositoryOverviewCardContentTitleStyle}>
                Total Technical debt
              </p>
              <div className="flex flex-row items-center gap-2">
                <p className={repositoryOverviewCardContentValueStyle}>
                  {repositoriesSummary?.total_tech_debt
                    ? `${repositoriesSummary.total_tech_debt} mins`
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="flex flex-col gap-6 font-satoshi">
        <div className="flex flex-row items-center justify-between ">
          <div className="flex flex-col items-start justify-center gap-2">
            <p className="text-[#0C101E] font-bold text-2xl tracking-[-0.03em]">
              Repositories ({repositoriesSummary?.total_repositories})
            </p>
            <p className="text-[#132052]/[.6] font-medium text-lg">
              Repositories that has been added to this lists
            </p>
          </div>
          <ManageRepositories />
          {/* todo add search bar and filter */}
        </div>
        <div className="flex flex-col w-full max-h-96">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="border-[#EAEBEF] border-[1.6px] rounded-2xl">
                <table className="min-w-full font-satoshi text-sm">
                  <thead className="bg-white rounded-2xl text-[#353B42] sticky top-0">
                    <tr className="grid grid-cols-6 gap-6 px-4 py-6 border-b-[1.6px] border-[#EAEBEF] border-solid ">
                      <th className="col-span-2 border-none bg-transparent">
                        <div className="flex flex-ro gap-4">Repository</div>
                      </th>
                      <th className="border-none bg-transparent">
                        <div className="flex flex-ro gap-4"></div>
                      </th>
                      <th className="border-none bg-transparent">
                        <div className="flex flex-ro gap-4"></div>
                      </th>
                      <th className="border-none bg-transparent">
                        <div className="flex flex-ro gap-4">
                          {/* Maintianability */}
                        </div>
                      </th>
                      <th className="border-none bg-transparent">
                        <div className="flex flex-ro gap-4">Last Commit</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="max-h-96 overflow-y-auto">
                    {repoList &&
                      repoList.map((repo) => (
                        <tr
                          key={repo.id}
                          className="border-l-0 border-r-0 border-t-0 border-b-[1.6px] border-[#EAEBEF] grid grid-cols-6 gap-6 items-center bg-transparent cursor-pointer"
                          onClick={() => openRepository(repo)}
                        >
                          <td className="col-span-2 px-4 py-6 border-none">
                            <div className="flex flex-row items-center gap-4">
                              <p className="text-[#132052] font-medium text-lg">
                                {repo.full_name}
                              </p>
                            </div>
                          </td>
                          <td className="px-4 py-6 border-none">
                            <div className="flex flex-row items-center gap-2">
                              <p className="text-[#0581E5] font-bold text-sm">
                                {repo.analysis?.security ?? ""}
                              </p>
                            </div>
                          </td>
                          <td className="px-4 py-6 border-none">
                            <div className="flex flex-row items-center gap-2">
                              <p className="text-[#0581E5] font-medium text-sm">
                                {repo.analysis?.complexity ?? ""}
                              </p>
                            </div>
                          </td>
                          <td className="px-4 py-6 border-none">
                            {repo.analysis?.maintainability_index ? (
                              <div className="inline-flex items-center gap-2 px-3 py-1 border-[1.6px] border-[#141b341a] rounded-[40px]">
                                <div className="w-6 h-6 bg-[#45C673] rounded-full flex flex-row items-center justify-center">
                                  <p className="text-white">
                                    {repo.analysis?.maintainability_index}
                                  </p>
                                </div>
                                <p className="text-[#5C6068] font-medium text-sm">
                                  {repo.analysis?.tech_debt
                                    ? `${repo.analysis?.tech_debt} mins`
                                    : ""}
                                </p>
                              </div>
                            ) : (
                              <div className="flex flex-row items-center gap-2">
                                <p className="text-[#0581E5] font-medium text-sm"></p>
                              </div>
                            )}
                          </td>

                          <td className="py-6 border-none">
                            <div className="flex flex-row items-center gap-2">
                              <p className="text-[#5C6068] font-bold text-sm">
                                {Moment(repo.last_commit).format(
                                  "MMM DD YYYY, h:mm A"
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RepositoriesPage;
