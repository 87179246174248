// src/redux/repoSlice.js
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Repository from '../Interfaces/Repository';
import File from '../Interfaces/File';
import Issue from '../Interfaces/Issue';
import RepositoriesSummary from '../Interfaces/RepositoriesSummary';

interface RepositoryState {
    repositories: Repository[] | null;
    repositoriesSummary: RepositoriesSummary | null;
    currentRepository: Repository | null;
    files: File[];
    currentFile: File | null;
    issues: Issue[];
}

const initialState: RepositoryState = {
    repositories: null,
    repositoriesSummary: null,
    currentRepository: null,
    files: [],
    currentFile: null,
    issues: [],
};

const repositorySlice = createSlice({
    name: 'repo',
    initialState,
    reducers: {
        setRepositories: (state, action: PayloadAction<Repository[]>) => {
            state.repositories = action.payload;
        },
        setCurrentRepository: (state, action: PayloadAction<Repository>) => {
            state.currentRepository = action.payload;
        },
        setRepositoriesSummary: (state, action: PayloadAction<RepositoriesSummary | null>) => {
            state.repositoriesSummary = action.payload;
        },
        setFiles: (state, action: PayloadAction<File[]>) => {
            state.files = action.payload;
        },
        setCurrentFile: (state, action: PayloadAction<File>) => {
            state.currentFile = action.payload;
        },
        setIssues: (state, action: PayloadAction<Issue[]>) => {
            state.issues = action.payload;
        },
    },
});

export const { setRepositories, setCurrentRepository, setRepositoriesSummary, setFiles, setCurrentFile, setIssues } = repositorySlice.actions;

export default repositorySlice.reducer;
