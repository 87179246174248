import CodeViewer from "../../Components/CodeViewer/CodeViewer";
import RepositoryTreeStructure, {
  TreeNode,
} from "./SubPages/RepositoryTreeStructure";
import { CodeViewerProps } from "../../Components/CodeViewer/CodeViewer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Cookies from "js-cookie";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { setCurrentFile, setFiles } from "../../redux/repositorySlice";
import File from "../../Interfaces/File";
import { franc } from "franc-min";
import { fetchFiles } from "../../utils/file";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import loadingSpinner from "../../Assets/Icons/loadingSpinner.gif";
import { v1 as uuidv1 } from "uuid";
import ProcessProgress from "../../Components/ProcessProgress/ProcessProgress";
import UniCodeEditor, {
  UniCodeEditorProps,
} from "../../Components/UniCodeEditor/UniCodeEditor";
import { HighlightLine } from "../../Components/CodeEditor/HighlightLine";

interface MethodMappings {
  [key: number]: number;
}

function RepositoryPage() {
  const dispatch = useDispatch();
  const currentRepository = useSelector(
    (state: RootState) => state.repository.currentRepository
  );
  const [repositoryFiles, setRepositoryFiles] = useState<File[]>([]);

  const [highlightedLines, setHighlightedLines] = useState<HighlightLine[]>([]);
  const [testErrorHighlightedLines, setTestErrorHighlightedLines] = useState<
    HighlightLine[]
  >([]);
  const [selectedFileContent, setSelectedFileContent] = useState("");

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [isFileSelected, setIsFileSelected] = useState(false);

  const [runnability, setRunnability] = useState<boolean | null>(null);
  const [testCoverage, setTestCoverage] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [codeLanguage, setCodeLanguage] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const [testFileName, setTestFileName] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [testCode, setTestCode] = useState("");

  const [sourceCodeLines, setSourceCodeLines] = useState<number | null>(null);
  const [testCodeLines, setTestCodeLines] = useState<number | null>(null);

  const [methodMappings, setMethodMappings] = useState<MethodMappings>({});
  const [focusedLine, setFocusedLine] = useState<number | undefined>();

  const userName = Cookies.get("userName");
  const base_url = `${process.env.REACT_APP_COVLANT_API_URL}/${userName}/${currentRepository?.name}`;

  const [sizes, setSizes] = useState([280, "35%"]);

  const mappingsRef = useRef({});

  useEffect(() => {
    const fetchFiles = async (branchId: string) => {
      try {
        axios.defaults.withCredentials = true;
        const res = await axios.post(
          `${process.env.REACT_APP_COVLANT_API_URL}/${Cookies.get(
            "userName"
          )}/${currentRepository?.name}/files/`,
          { branch_id: branchId },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
            withCredentials: true,
          }
        );
        if (res.data) {
          setRepositoryFiles(res.data);
          dispatch(setFiles(res.data));
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (currentRepository != null && currentRepository.branches.length > 0) {
      const defaultBranch = currentRepository.branches.find(
        (branch) => branch.is_default === true
      );
      if (defaultBranch) {
        fetchFiles(defaultBranch.id.toString());
      }
    }
  }, [dispatch, currentRepository]);

  const GetSourceCode = async (currentFile: File | undefined) => {
    try {
      axios.defaults.withCredentials = true;

      if (!currentFile) {
        console.error("currentFile is undefined");
        return;
      }

      axios
        .post(
          `${base_url}/${currentFile.name}/source_code/`,
          {
            file_id: currentFile.id,
          },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.success) {
            setSelectedFileContent(res.data.source_code);
            setIsFileSelected(true);
            const language = franc(res.data.source_code);
            setCodeLanguage(language);
            setSelectedFileName(currentFile.name);
            setSourceCodeLines(computeLOCandSize(res.data.source_code));
          } else {
            setSelectedFileContent("File not found");
            setIsFileSelected(false);
            setCodeLanguage("java");
          }
        })
        .catch((error) => {
          console.error("Error in axios post request:", error);
        });
    } catch (error) {
      console.error("Error in GetSourceCode function:", error);
    }
  };

  const GetTestCode = async (currentFile: File | undefined) => {
    try {
      axios.defaults.withCredentials = true;

      if (!currentFile) {
        console.error("currentFile is undefined");
        return;
      }

      axios
        .post(
          `${base_url}/${currentFile.name}/test_cases/saved`,
          {
            file_id: currentFile?.id,
          },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data) {
            setTestCode(res.data.test_code);
            setRunnability(res.data.is_runnable);
            setTestCoverage(res.data.coverage);
            setTestCodeLines(computeLOCandSize(res.data.test_code));
            setTestFileName(res.data.test_file_name);
            // updateCurrentFiles();
            const { covered_lines, excluded_lines } = res.data;
            let highlightLines: HighlightLine[] = [];
            let errorLines: HighlightLine[] = [];

            covered_lines.forEach((line: string) => {
              highlightLines.push({
                lineNumber: parseInt(line),
                color: "green",
              });
            });

            excluded_lines.forEach((line: string) => {
              highlightLines.push({ lineNumber: parseInt(line), color: "red" });
            });
            setHighlightedLines(highlightLines);

            res.data.error_line_numbers.forEach((line: string) => {
              errorLines.push({ lineNumber: parseInt(line), color: "red" });
            });
            setTestErrorHighlightedLines(errorLines);
          } else {
            setTestCode("");
            setRunnability(null);
            setTestCoverage(null);
            setHighlightedLines([]);
          }
        });
    } catch (error) {
      setTestCode("Something went wrong please contact ADMIN.");
      console.error(error);
    }
  };

  const handleNodeClick = async (node: TreeNode) => {
    if (node.file !== selectedFile) {
      setTestCode("");
      setIsLoading(false);
      setIsGenerating(false);
      setSelectedFileContent("");
      setCodeLanguage("");
      setHighlightedLines([]);
    }
    if (node.file) dispatch(setCurrentFile(node.file));
    setSelectedFile(node.file);
    GetSourceCode(node.file);
    GetTestCode(node.file);
  };

  const Popup = () => {
    return (
      <div className="popup">
        <p>{popupMessage}</p>
      </div>
    );
  };

  const computeLOCandSize = (code: string) => {
    const lines = code.split("\n").length;
    // const size = new Blob([code]).size;
    return lines;
  };

  const validateLanguageAndFileType = (file: File) => {
    if (file.name.endsWith(".java") || file.name.endsWith(".py")) {
      return true;
    } else {
      setPopupMessage(
        "Code generation is currently enabled only for Java and python files."
      );
      setShowPopup(true);
      return false;
    }
  };

  const handleGenerateTestCases = async (file: File | undefined) => {
    setHighlightedLines([]);
    setTestErrorHighlightedLines([]);
    if (file === undefined) {
      setPopupMessage("Code generation failed because of Technical Issue.");
      setShowPopup(true);
      return;
    }
    // Assuming currentFile is the selected file
    const isValid = validateLanguageAndFileType(file);
    if (isValid) {
      // If the file is valid, get the test code
      setIsLoading(true);
      await GenerateTestCode(file.id);
    } else {
      // If the file is not valid, show a popup
      setPopupMessage(
        "Code generation is currently enabled only for Java and python files."
      );
      setShowPopup(true);
    }
  };

  const handleReRunTestCases = async (
    file: File | undefined,
    test_code: string
  ) => {
    setHighlightedLines([]);
    setTestErrorHighlightedLines([]);
    if (file === undefined) {
      setPopupMessage("Code generation failed because of Technical Issue.");
      setShowPopup(true);
      return;
    }
    setIsLoading(true);
    await ReRunTestCode(file.id, test_code);
  };

  const updateCurrentFiles = async () => {
    if (currentRepository)
      fetchFiles(currentRepository).then((res) => {
        if (res?.data) {
          dispatch(setFiles(res.data));
        }
      });
  };

  const GenerateTestCode = async (file_id: number) => {
    try {
      axios.defaults.withCredentials = true;
      setIsGenerating(true);
      const newRequestId = uuidv1();
      await setRequestId(newRequestId);
      axios
        .post(
          `${base_url}/${file_id}/test_cases_rag/`,
          {
            file_id: file_id,
            request_id: newRequestId,
          },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
            withCredentials: true,
          }
        )
        .then((res: any) => {
          if (res.data) {
            setIsGenerating(false);
            setIsLoading(false);
            setRequestId("");
            setTestCode(res.data.test_code);
            setTestCodeLines(computeLOCandSize(res.data.test_code));
            setRunnability(res.data.is_runnable);
            setTestCoverage(res.data.coverage);
            setTestFileName(res.data.test_file_name);
            // updateCurrentFiles();
            const { covered_lines, excluded_lines } = res.data;

            let highlightLines: HighlightLine[] = [];
            let errorLines: HighlightLine[] = [];

            covered_lines.forEach((line: string) => {
              highlightLines.push({
                lineNumber: parseInt(line),
                color: "green",
              });
            });

            excluded_lines.forEach((line: string) => {
              highlightLines.push({ lineNumber: parseInt(line), color: "red" });
            });
            setHighlightedLines(highlightLines);
            res.data.error_line_numbers.forEach((line: string) => {
              errorLines.push({ lineNumber: parseInt(line), color: "red" });
            });
            setTestErrorHighlightedLines(errorLines);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            (error.response.status === 500 || error.response.status === 400)
          ) {
            setTestCode("Test code generation failed. Please contact ADMIN.");
          }
          console.error(error);
        });
    } catch (error) {
      setTestCode("Something went wrong please contact ADMIN.");
      console.error(error);
    }
  };

  const ReRunTestCode = async (file_id: number, test_code: string) => {
    try {
      axios.defaults.withCredentials = true;
      setIsGenerating(true);
      const newRequestId = uuidv1();
      await setRequestId(newRequestId);
      axios
        .post(
          `${base_url}/${file_id}/run-testcode`,
          {
            file_id: file_id,
            test_code: test_code,
            request_id: newRequestId,
          },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
            withCredentials: true,
          }
        )
        .then((res: any) => {
          if (res.data) {
            setIsGenerating(false);
            setIsLoading(false);
            setRequestId("");
            setTestCode(res.data.test_code);
            setTestCodeLines(computeLOCandSize(res.data.test_code));
            setRunnability(res.data.is_runnable);
            setTestCoverage(res.data.coverage);
            setTestFileName(res.data.test_file_name);
            // updateCurrentFiles();
            const { covered_lines, excluded_lines } = res.data;

            let highlightLines: HighlightLine[] = [];
            let errorLines: HighlightLine[] = [];

            covered_lines.forEach((line: string) => {
              highlightLines.push({
                lineNumber: parseInt(line),
                color: "green",
              });
            });

            excluded_lines.forEach((line: string) => {
              highlightLines.push({ lineNumber: parseInt(line), color: "red" });
            });
            setHighlightedLines(highlightLines);
            res.data.error_line_numbers.forEach((line: string) => {
              errorLines.push({ lineNumber: parseInt(line), color: "red" });
            });
            setTestErrorHighlightedLines(errorLines);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            (error.response.status === 500 || error.response.status === 400)
          ) {
            setTestCode("Test code generation failed. Please contact ADMIN.");
          }
          console.error(error);
        });
    } catch (error) {
      setTestCode("Something went wrong please contact ADMIN.");
      console.error(error);
    }
  };

  const fetchMethodMappings = async (sourceCode: string, testCode: string) => {
    try {
      axios.defaults.withCredentials = true;

      const response = await axios.post(
        `${process.env.REACT_APP_COVLANT_API_URL}/create-mapping`,
        {
          source_code: sourceCode,
          test_code: testCode,
        },
        {
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
          withCredentials: true,
        }
      );
      const mappings = response.data["mapping"];

      const numberMappings: MethodMappings = {};
      Object.keys(mappings).forEach((key) => {
        const numericKey = parseInt(key, 10);
        numberMappings[numericKey] = mappings[key];
      });

      console.log("Converted Mappings:", numberMappings);

      return numberMappings;
    } catch (error) {
      console.error("Failed to fetch method mappings:", error);
      return {};
    }
  };

  useEffect(() => {
    setMethodMappings({});
    const fetchMappings = async () => {
      const newMappings = await fetchMethodMappings(
        selectedFileContent,
        testCode
      );
      console.log("Setting new mappings:", newMappings);
      setMethodMappings((prevMappings) => ({
        ...prevMappings,
        ...newMappings,
      }));
    };

    if (selectedFileContent && testCode) {
      fetchMappings();
    }
  }, [selectedFileContent, testCode]);

  useEffect(() => {
    mappingsRef.current = methodMappings;
    console.log("refValue", mappingsRef.current);
  }, [methodMappings]);

  const handleResetFocusedLine = () => {
    setFocusedLine(undefined);
  };

  const handleSourceLineClick = (lineNumber: number) => {
    const mappings: MethodMappings = mappingsRef.current;
    const mappedLine = mappings[lineNumber];
    console.log("Method mappings at click:", mappings);
    console.log("Line number:", lineNumber);
    console.log("Focused line:", mappedLine);
    if (mappedLine) {
      setFocusedLine(mappedLine);
    }
  };

  // const sourceCodeProps: CodeViewerProps = {
  //   file: selectedFile,
  //   fileName: selectedFileName,
  //   code: selectedFileContent,
  //   language: codeLanguage,
  //   isTestCode: false,
  //   isLoading: false,
  //   isFileSelected: isFileSelected,
  //   highlightedLines: highlightedLines,
  //   lines: sourceCodeLines,
  // };

  const sourceCodeProps: UniCodeEditorProps = {
    fileName: selectedFileName,
    code: selectedFileContent,
    isReadOnly: true,
    isTestCode: false,
    isLoading: false,
    isFileSelected: isFileSelected,
    highlightedLines: highlightedLines,
    lines: sourceCodeLines,
    onLineClick: handleSourceLineClick,
  };

  // const testCodeProps: CodeViewerProps = {
  //   code: testCode,
  //   fileName: testFileName,
  //   isTestRunnable: runnability,
  //   testCoverage: testCoverage,
  //   language: codeLanguage,
  //   isTestCode: true,
  //   isFileSelected: isFileSelected,
  //   isLoading: isLoading,
  //   lines: testCodeLines,
  //   highlightedLines: testErrorHighlightedLines,
  //   onGenerateTestCases: () => handleGenerateTestCases(selectedFile),
  // };

  const testCodeProps: UniCodeEditorProps = {
    code: testCode,
    fileName: testFileName,
    isReadOnly: false,
    isTestRunnable: runnability,
    testCoverage: testCoverage,
    isTestCode: true,
    isFileSelected: isFileSelected,
    isLoading: isLoading,
    lines: testCodeLines,
    highlightedLines: testErrorHighlightedLines,
    onGenerateTestCases: () => handleGenerateTestCases(selectedFile),
    onReRunTestCode: (test_code) =>
      handleReRunTestCases(selectedFile, test_code),
    focusedLine: focusedLine,
    onResetFocusedLine: handleResetFocusedLine,
  };

  return repositoryFiles.length > 0 ? (
    <div className="w-full mx-4 border-t">
      <SplitPane
        split="vertical"
        sizes={sizes}
        sashRender={() => null}
        onChange={(sizes) => setSizes(sizes)}
      >
        <div className="h-full overflow-auto">
          <RepositoryTreeStructure
            data={repositoryFiles}
            handleNodeClick={handleNodeClick}
          />
        </div>
        <div className="h-full border-r overscroll-y-auto	overflow-auto">
          {/* <CodeViewer
            key={JSON.stringify(highlightedLines)}
            {...sourceCodeProps}
          /> */}
          <UniCodeEditor key={highlightedLines.length} {...sourceCodeProps} />
        </div>
        <div className="h-full overscroll-y-auto overflow-auto">
          {isGenerating ? (
            <ProcessProgress
              url={`${process.env.REACT_APP_COVLANT_API_URL}/process-step/${requestId}/TEST_CASE_GENERATION/`}
            />
          ) : (
            // <CodeViewer
            //   key={JSON.stringify(highlightedLines)}
            //   {...testCodeProps}
            // />
            <UniCodeEditor {...testCodeProps} />
          )}
        </div>
      </SplitPane>

      {showPopup && <Popup />}
    </div>
  ) : (
    <div className="w-full flex flex-col items-center justify-center gap-4">
      <img src={loadingSpinner} alt="loading" className="h-14 w-14" />
    </div>
  );
}

export default RepositoryPage;
