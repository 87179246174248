import ManageRepositories from "../../Components/ManageRepositories/ManageRepositories";

const DashboardPage = () => {
  return (
    <div className="flex flex-grow items-center">
      <div className="flex flex-col flex-grow  pb-32 gap-16 m-1">
        <div className="font-satoshi  text-center  justify-center">
          <p className="font-bold  text-[#132052] text-[36px] tracking-[-.03em]">Welcome to Covlant</p>
          <p className="text-[#132052]/[0.6] font-normal text-[18px]">Automated code review, code analytics and code generation</p>
        </div>
        <div className="mx-auto">
          <ManageRepositories />
        </div>

        {/* <div className="w-full border-solid border-[1.6px] border-[#EAEBEF] rounded-2xl flex flex-row items-center gap-6 p-6">
          <div className="flex flex-row flex-grow justify-between items-center">
            <div className="flex flex-col gap-2 font-satoshi">
              <p className="text-[#132052]/[0.6] text-[16px] font-normal">2 Repositories</p>
              <p className="text-[#132052] font-bold text-[24px]">Open Source</p>
            </div>
            <div className="flex flex-row gap-6">
              <div className={gitProviderIconStyle}>
                <img className={gitProviderIconHeightWidth} src={githubIcon} alt="github logo" />
              </div>
              <div className={gitProviderIconStyle}>
                <img className={gitProviderIconHeightWidth} src={gitlabIcon} alt="gitlab logo" />
              </div>
            </div>
          </div>
          <div className="">
            <RiArrowRightSLine />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DashboardPage;
