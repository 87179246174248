import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./Pages/Login/LoginPage";
import DashboardPage from "./Pages/Dashboard/DashboardPage";
import RepositoriesPage from "./Pages/Repositories/RepositoriesPage";
import Layout from "./Pages/Layout/Layout";
import TestCase from "./Pages/TestCase/TestCase";
import LandingPage from "./Pages/LandingPage/LandingPage";
// import RepositoryDashboardPage from "./Pages/RepositoryDashboard/RepositoryDashboardPage";
import RegistrationPage from "./Pages/Registration/ResgistrationPage";
import ProtectedRoute from "./ProtectedRoute";
import { AuthProvider } from "./auth";
// import RepositoryAudit from "./Pages/RepositoryDashboard/SubPages/Audit";
// import RepositoryOverview from "./Pages/RepositoryDashboard/SubPages/Overview";
// import RepositoryIssues from "./Pages/RepositoryDashboard/SubPages/Issues";
// import RepositoryFiles from "./Pages/RepositoryDashboard/SubPages/Files";
// import RepositoryFile from "./Pages/RepositoryDashboard/SubPages/File/File";
import RepositoryPage from "./Pages/Repository/RepositoryPage";
import BetaSignup from "./Pages/BetaSignup/BetaSignup";
import ContactPage from "./Pages/Contact/Contact";
import { FolderProvider } from "./Contexts/FolderContext";

function App() {
  return (
    <AuthProvider>
      <FolderProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/beta-signup" element={<BetaSignup />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route
            path="/:userName"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="repositories" element={<RepositoriesPage />} />
            <Route
              path="repositories/:repositoryName"
              element={<RepositoryPage />}
            />
            {/* <Route path=":repositoryName" element={<RepositoryPage />}>
            <Route path="overview" element={<RepositoryOverview />} />
            <Route path="issues" element={<RepositoryIssues />} />
            <Route path="files" element={<RepositoryFiles />} />
            <Route path="file/:fileName/*" element={<RepositoryFile />} />
            <Route path="policy" element={<RepositoryAudit />} />
            <Route path="settings" element={<RepositoryAudit />} />
            <Route path="audit" element={<RepositoryAudit />} />
          </Route> */}
            <Route
              path=":repositoryName/:name/test_cases"
              element={<TestCase />}
            />
          </Route>
        </Routes>
      </FolderProvider>
    </AuthProvider>
  );
}
export default App;
