import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons";

interface Step {
  name: string;
  status: "completed" | "in_progress" | "pending";
}

interface Process {
  name: string;
  steps: Step[];
}

export interface Props {
  url: string;
}

const ProcessProgress = ({ url }: Props) => {
  const [process, setProcess] = useState<Process>({ name: "", steps: [] });
  const [initialDataFetched, setInitialDataFetched] = useState(false);

  useEffect(() => {
    const fetchProcess = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
        });
        const stepsData: any[] = await response.json();
        const processName =
          stepsData.length > 0 ? stepsData[0].process_name : "";
        const steps = stepsData.map((step) => ({
          name: step.step_name,
          status: step.step_status,
        }));
        setProcess({ name: processName, steps });
        if (steps.length > 0) setInitialDataFetched(true);
      } catch (error) {
        console.error("Failed to fetch process:", error);
      }
    };

    fetchProcess();

    const pollingInterval = initialDataFetched ? 3000 : 200;
    const intervalId = setInterval(fetchProcess, pollingInterval);

    return () => clearInterval(intervalId);
  }, [url, initialDataFetched]);

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="h-full flex flex-col items-start justify-center p-4">
        {process.steps.map((step, index) => (
          <div key={index} className="flex flex-row justify-between w-full">
            <div className="mr-[10px]">{step.name}:</div>
            {step.status === "completed" ? (
              <div>
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
              </div>
            ) : step.status === "in_progress" ? (
              <div>
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  style={{ color: "green" }}
                />
              </div>
            ) : (
              <div>
                <span>Pending</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProcessProgress;
