import { Outlet } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const Layout = () => {
  return (
    <div className="flex flex-col h-screen ">
      <Header />
      <div className="flex flex-grow w-full mx-auto">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
