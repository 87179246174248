import copyIcon from "../../Assets/Icons/copyIcon.png";
import downloadIcon from "../../Assets/Icons/downloadIcon.png";

import axios from "axios";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import loadingSpinner from "../../Assets/Icons/loadingSpinner.gif";
import CodeEditor, { CodeEditorMethods } from "../CodeEditor/CodeEditor";
import { HighlightLine } from "../CodeEditor/HighlightLine";
import { useEffect, useRef, useState } from "react";
import "./UniCodeEditor.css";

export interface UniCodeEditorProps {
  code: string;
  isReadOnly: boolean;
  fileName?: string;
  highlightedLines: HighlightLine[];
  canCopyCode?: boolean;
  displayCodeDetails?: boolean;
  isTestCode: boolean;
  isTestRunnable?: boolean | null;
  testCoverage?: number | null;
  isFileSelected: boolean;
  onGenerateTestCases?: () => void;
  onReRunTestCode?: (test_code: string) => void;
  isLoading: boolean;
  lines?: number | null;
  size?: number;
  onLineClick?: (lineNumber: number) => void;
  focusedLine?: number;
  onResetFocusedLine?: () => void;
}

const UniCodeEditor: React.FC<UniCodeEditorProps> = (uniCodeEditorProps) => {
  const [currentCode, setCurrentCode] = useState<string>(
    uniCodeEditorProps.code
  );
  const userName = Cookies.get("userName");
  const currentRepository = useSelector(
    (state: RootState) => state.repository.currentRepository
  );
  const currentFile = useSelector(
    (state: RootState) => state.repository.currentFile
  );
  const [isCodeEdited, setIsCodeEdited] = useState(false);

  useEffect(() => {
    setCurrentCode(uniCodeEditorProps.code);
  }, [uniCodeEditorProps.code]);

  const handleSave = async (newCode: string) => {
    if (currentCode !== newCode) {
      await setCurrentCode(newCode);
      saveTestCode(newCode);
      setIsCodeEdited(true);
    }
  };

  const base_url = `${process.env.REACT_APP_COVLANT_API_URL}/${userName}/${currentRepository?.name}/${currentFile?.id}`;

  const saveTestCode = async (updatedCode: string) => {
    if (!uniCodeEditorProps.isTestCode) {
      return;
    }
    try {
      console.log(updatedCode);
      axios.defaults.withCredentials = true;
      axios
        .post(
          `${base_url}/test_cases/save`,
          {
            file_id: currentFile?.id,
            test_code: updatedCode,
          },
          {
            headers: {
              "X-CSRFToken": Cookies.get("csrftoken"),
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data) {
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const editorRef = useRef<CodeEditorMethods>(null);

  useEffect(() => {
    console.log(
      "Attempting to focus line: ",
      uniCodeEditorProps.focusedLine,
      "Editor ref:",
      editorRef.current
    );

    if (
      uniCodeEditorProps.isTestCode &&
      uniCodeEditorProps.focusedLine !== null &&
      uniCodeEditorProps.focusedLine !== undefined
    ) {
      editorRef.current?.focusLine(uniCodeEditorProps.focusedLine);
    }
  }, [uniCodeEditorProps.focusedLine, uniCodeEditorProps.isTestCode]);

  const downloadStringAsFile = (content: string, filename: string) => {
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    let fileName = "testCode.java";
    if (uniCodeEditorProps.fileName) {
      fileName = uniCodeEditorProps.fileName;
    } else if (currentFile?.name) {
      const fileNameWithoutExtension = currentFile?.name
        ?.split(".")
        .slice(0, -1)
        .join(".");
      fileName = `${fileNameWithoutExtension}Test.java`;
    }
    if (currentCode) downloadStringAsFile(currentCode, fileName);
  };

  if (!uniCodeEditorProps.isFileSelected) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <p>Please select a file.</p>
      </div>
    );
  }

  const GenerateTestsButton: React.FC<{ onGenerateTestCases?: () => void }> = ({
    onGenerateTestCases,
  }) => (
    <button
      className="rounded-lg bg-[#0581E5] py-3 px-4 flex items-center text-white font-bold text-sm font-satoshi"
      onClick={onGenerateTestCases}
    >
      Generate Tests
    </button>
  );

  if (uniCodeEditorProps.isTestCode && !uniCodeEditorProps.code) {
    return (
      <div className="h-full">
        {uniCodeEditorProps.isLoading ? (
          <div className="flex flex-col items-center justify-center gap-4">
            <img src={loadingSpinner} alt="loading" className="h-14 w-14" />
            <div className="text-black font-bold font-satoshi text-base">
              Please wait Test cases are getting generated...
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <GenerateTestsButton
              onGenerateTestCases={uniCodeEditorProps.onGenerateTestCases}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="w-full h-full">
      {uniCodeEditorProps.code && (
        <div className="flex flex-col p-6 gap-6 pr-0">
          <div className="flex flex-row gap-8 font-satoshi items-center">
            <p className="font-bold text-[15px] text-[#353B42]">
              {uniCodeEditorProps.isTestCode ? "Test code" : "Source code"}
            </p>
            {(uniCodeEditorProps.lines !== 0 ||
              uniCodeEditorProps.size !== 0) && (
              <div className="flex flex-row items-center gap-2 text-[#5C6068] font-medium text-sm">
                <p className="text-[#2742AB] font-bold">
                  {uniCodeEditorProps.fileName}
                </p>
                <p className="text-center align-middle">•</p>
                <p>{uniCodeEditorProps.lines} Lines</p>
                {uniCodeEditorProps.isTestCode && (
                  <div>
                    <p
                      className={`${
                        uniCodeEditorProps.isTestRunnable
                          ? "text-green-500"
                          : "text-red-500"
                      } font-bold text-[15px]`}
                    >
                      {uniCodeEditorProps.isTestRunnable
                        ? "Runnable"
                        : uniCodeEditorProps.isTestRunnable != null
                        ? "Not Runnable"
                        : ""}
                    </p>
                    {uniCodeEditorProps.testCoverage != null && (
                      <p className="text-blue-500 font-bold text-[15px]">
                        Coverage: {uniCodeEditorProps.testCoverage}
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            {uniCodeEditorProps.isTestCode && (
              <div className="flex flex-row items-center gap-4 ml-auto">
                {uniCodeEditorProps.code ? (
                  <div className="flex flex-row items-center gap-2 ml-auto">
                    <div
                      className="flex flex-row py-3 px-4 rounded-lg border-[1.6px] border-solid border-[#EAEBEF] cursor-pointer h-[44px]"
                      onClick={handleDownload}
                    >
                      <img src={downloadIcon} alt="Download" />
                    </div>
                    <div
                      className="flex flex-row py-3 px-4 rounded-lg border-[1.6px] border-solid border-[#EAEBEF] cursor-pointer"
                      onClick={() => {
                        if (currentCode) {
                          navigator.clipboard.writeText(currentCode);
                        } else {
                          console.log("Code is not present");
                        }
                      }}
                    >
                      <img src={copyIcon} alt="Copy" />
                    </div>
                    <button
                      className="rounded-lg bg-[#0581E5] py-3 px-4 flex items-center text-white font-bold text-sm font-satoshi justify-center"
                      onClick={uniCodeEditorProps.onGenerateTestCases}
                    >
                      Regenerate
                    </button>
                    {isCodeEdited && (
                      <button
                        className="rounded-lg bg-[#0581E5] py-3 px-4 flex items-center text-white font-bold text-sm font-satoshi justify-center"
                        onClick={() => {
                          if (uniCodeEditorProps.onReRunTestCode) {
                            uniCodeEditorProps.onReRunTestCode(currentCode);
                          }
                        }}
                      >
                        Rerun
                      </button>
                    )}
                  </div>
                ) : (
                  <button
                    className="rounded-lg bg-[#0581E5] py-3 px-4 flex items-center text-white font-bold text-sm font-satoshi justify-center"
                    onClick={uniCodeEditorProps.onGenerateTestCases}
                  >
                    Generate Tests
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <div style={{ overflowY: "auto", height: "100%" }}>
        {currentCode ? (
          uniCodeEditorProps.isTestCode ? (
            <CodeEditor
              ref={editorRef}
              code={currentCode}
              highlightLines={uniCodeEditorProps.highlightedLines}
              isReadOnly={uniCodeEditorProps.isReadOnly}
              onSave={handleSave}
              restFocusLine={uniCodeEditorProps.onResetFocusedLine}
            />
          ) : (
            <CodeEditor
              code={currentCode}
              highlightLines={uniCodeEditorProps.highlightedLines}
              isReadOnly={uniCodeEditorProps.isReadOnly}
              onLineClick={uniCodeEditorProps.onLineClick}
            />
          )
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center gap-4">
            <img src={loadingSpinner} alt="loading" className="h-14 w-14" />
          </div>
        )}
      </div>
    </div>
  );
};

export default UniCodeEditor;
