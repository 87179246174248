import React, { useEffect, useState } from "react";
import logo from "../../Assets/Logo/covlantLogoLightTheme.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import axios from "axios";
import userCircleIcon from "../../Assets/Icons/userCircle.svg";
import logoutIcon from "../../Assets/Icons/logoutIcon.png";
import { useAuth } from "../../auth";
import Cookies from "js-cookie";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isAuthenticated, setIsAuthenticated, setIsLoading } = useAuth();
  const navigate = useNavigate();

  const landingPageHeaderMenuItemStyle = `text-[#00023F] font-bold text-base p-3 font-satoshi`;
  const landingPageActionButtonStyle = `px-3 py-6  h-[46px] rounded-[12px] border-solid border-[#2742AB] border font-bold text-base font-satoshi`;

  const [isRepositoryPage, setIsRepositoryPage] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  const showRepositoriesButton =
    pathname.includes("/overview") ||
    pathname.includes("/issues") ||
    pathname.includes("/policy") ||
    pathname.includes("/settings") ||
    pathname.includes("/test_cases") ||
    pathname.includes("/audit") ||
    (pathname.includes("/repositories/") && pathname.split("/").length > 2);
  pathname.includes("/files");

  useEffect(() => {
    setIsRepositoryPage(
      pathname.includes("/repositories/") && pathname.split("/").length > 2
    );
  }, [pathname]);

  const handleLogout = async () => {
    await axios.post(
      `${process.env.REACT_APP_COVLANT_API_URL}/logout/`,
      {},
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        withCredentials: true,
      }
    );
    setIsAuthenticated(false);
    setIsLoading(false);
    navigate("/", { replace: true });
  };

  return (
    <nav
      className={`w-full mx-auto ${
        isRepositoryPage ? "" : "sm:max-w-[1170px]"
      }`}
    >
      <div className="flex flex-col sm:flex-row justify-between px-2 sm:px-4 py-2">
        <div className="flex flex-row justify-between">
          <Link to="/" className="py-6">
            <img src={logo} alt="logo" />
          </Link>
          <button className="sm:hidden" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
          </button>
        </div>
        {isAuthenticated ? (
          <div className={`sm:flex w-full ml-6 ${isOpen ? "block" : "hidden"}`}>
            {showRepositoriesButton && (
              <div className="flex flex-row items-center">
                <Link
                  to={`/${Cookies.get("userName")}/repositories`}
                  className="font-satoshi font-bold text-base text-black"
                >
                  Repositories
                </Link>
              </div>
            )}
            <div className="flex flex-col sm:flex-row items-center ml-auto">
              <div className={landingPageHeaderMenuItemStyle}>
                <div
                  className="flex gap-2 cursor-pointer"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <img
                    src={userCircleIcon}
                    alt="User Avatar"
                    className="inline"
                  />
                  <span className="hidden sm:inline">
                    {Cookies.get("userName")}
                  </span>
                </div>
                {isDropdownOpen && (
                  <div
                    className="absolute mt-2 py-4 px-6 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 hover:bg-gray-100 cursor-pointer"
                    onClick={handleLogout}
                  >
                    <div
                      className="py-1 flex flex-row gap-2"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <button
                        className="flex flex-row gap-2 text-sm text-gray-700"
                        role="menuitem"
                      >
                        <img
                          src={logoutIcon}
                          alt="Logout Icon"
                          className="inline"
                        />
                        <p>Logout</p>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={`sm:flex ${isOpen ? "block" : "hidden"}`}>
            <div className="flex flex-col sm:flex-row items-center">
              <div className={landingPageHeaderMenuItemStyle}>
                <Link to="/products">Products</Link>
              </div>
              <div className={landingPageHeaderMenuItemStyle}>
                <Link to="/resources">Resources</Link>
              </div>
              <div className={landingPageHeaderMenuItemStyle}>
                <Link to="/company">Company</Link>
              </div>
              <div className={landingPageHeaderMenuItemStyle}>
                <Link to="/pricing">Pricing</Link>
              </div>
            </div>
          </div>
        )}
        {!isAuthenticated && (
          <div className={`sm:flex ${isOpen ? "block" : "hidden"}`}>
            <div className="flex flex-col sm:flex-row items-center gap-4">
              <button
                className={`${landingPageActionButtonStyle} text-[#2742AB]`}
                onClick={() => (window.location.href = "/login")}
              >
                Sign In
              </button>
              <button
                className={`${landingPageActionButtonStyle} bg-[#2742AB] text-white`}
                onClick={() => (window.location.href = "/register")}
              >
                Get Started
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
