import { NodeRendererProps, Tree } from "react-arborist";
import styles from "./Tree.module.css";
import clsx from "clsx";

import File from "../../../Interfaces/File";
import {
  FaFolder,
  FaFileCode,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileVideo,
  FaFileAudio,
  FaFile,
  FaFolderOpen,
} from "react-icons/fa";
import { useState } from "react";
import { useFolder } from "../../../Contexts/FolderContext";

export interface TreeNode {
  id: string;
  name: string;
  file_id: String;
  file: File | undefined;
  children: TreeNode[];
  isLeaf: boolean;
}

interface RepositoryTreeStructureProps {
  data: File[];
  handleNodeClick: (node: TreeNode) => void;
}

function convertToTreeData(data: File[]): TreeNode[] {
  const root: TreeNode = {
    id: "root",
    name: "root",
    file_id: "root",
    file: undefined,
    children: [],
    isLeaf: false,
  };

  data.forEach((item) => {
    const pathParts = item.path.split("/");
    let currentNode = root;

    pathParts.forEach((part, index) => {
      let foundNode = currentNode.children.find(
        (child: TreeNode) => child.name === part
      );

      if (!foundNode) {
        foundNode = {
          id: `${item.id}_${index}`,
          name: part,
          file_id: `${item.id}`,
          file: item,
          children: [],
          isLeaf: index === pathParts.length - 1, // Set isLeaf to true if it's the last part of the path
        };
        currentNode.children.push(foundNode);
      }

      currentNode = foundNode;
    });
  });

  return root.children;
}

function Node({
  node,
  style,
  handleNodeClick,
}: NodeRendererProps<any> & { handleNodeClick: (node: any) => void }) {
  /* This node instance can do many things. See the API reference. */
  // const get_node_icon = ({ node }: NodeRendererProps<any>) => {
  //   return node.isLeaf ? <img src={fileIcon} alt="File" className="w-4 h-4" /> : <img src={folderIcon} alt="Branch Icon" className="w-4 h-4" />;
  // };
  const { openFolders, toggleFolder } = useFolder();

  function getIcon(node: any) {
    if (!node.data.isLeaf) {
      const isOpen = !!openFolders[node.data.id];
      return isOpen ? (
        <FaFolderOpen color="#447CBF" />
      ) : (
        <FaFolder color="#15C0E3" />
      );
    }

    const extension = node.data.name.split(".").pop();

    switch (extension) {
      case "js":
      case "ts":
      case "jsx":
      case "tsx":
      case "html":
      case "css":
      case "java":
      case "py":
      case "c":
      case "cpp":
      case "cs":
      case "go":
      case "rb":
      case "php":
      case "swift":
      case "kt":
      case "rs":
      case "lua":
      case "r":
      case "pl":
      case "m":
      case "f":
      case "asm":
      case "sql":
      case "sh":
      case "bat":
      case "ps1":
        return <FaFileCode color="#5cb85c" />;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "bmp":
        return <FaFileImage color="#d9534f" />;
      case "pdf":
        return <FaFilePdf color="#d9534f" />;
      case "doc":
      case "docx":
        return <FaFileWord color="#337ab7" />;
      case "xls":
      case "xlsx":
        return <FaFileExcel color="#5cb85c" />;
      case "ppt":
      case "pptx":
        return <FaFilePowerpoint color="#f0ad4e" />;
      case "mp4":
      case "avi":
      case "mov":
        return <FaFileVideo color="#5bc0de" />;
      case "mp3":
      case "wav":
        return <FaFileAudio color="#5bc0de" />;
      default:
        return <FaFile color="#777" />;
    }
  }

  const handleClick = (node: any) => {
    if (!node.data.isLeaf) {
      toggleFolder(node.data.id);
      node.toggle();
    } else {
      handleNodeClick(node.data);
    }
  };

  return (
    <div
      style={style}
      className={clsx(styles.node, node.state)}
      onClick={() => handleClick(node)}
    >
      {getIcon(node)}
      {node.data.name}
    </div>
  );
}

function RepositoryTreeStructure({
  data,
  handleNodeClick,
}: RepositoryTreeStructureProps) {
  var treeData = convertToTreeData(data);

  return (
    treeData && (
      <div className="w-full h-full border-r-2 border-right-solid border-right-black overflow-auto">
        <div className="overflow-auto">
          <Tree
            initialData={treeData}
            openByDefault={false}
            width={"100%"}
            indent={3}
            height={700}
            rowHeight={36}
            overscanCount={1}
            paddingTop={30}
            paddingBottom={10}
            padding={25}
          >
            {(nodeProps) => (
              <Node {...nodeProps} handleNodeClick={handleNodeClick} />
            )}
          </Tree>
        </div>
      </div>
    )
  );
}

export default RepositoryTreeStructure;
